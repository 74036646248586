import { ACTION_LIST, WORKFLOW, WORKFLOW_LIST } from 'routes/constant-route';
import { Branch } from 'store/slices/branches';
import { Navigate, Outlet, Route } from 'react-router-dom';
import { RouteGuard } from 'components/RouteGuards/AuthGuard';
import { SuspenseOutlet, delayedLazy } from 'routes/helpers';
import { isArrayNullOrEmpty } from '@borda/cat-core';

const ActionList = delayedLazy(() => import('views/WorkflowsAndActions/Actions/Actions'));
const WorkflowList = delayedLazy(() => import('views/WorkflowsAndActions/Workflows/Workflows'));
const CreateWorkflowSettings = delayedLazy(
  () => import('views/WorkflowsAndActions/Modify/WorkflowSettings/CreateWorkflowSettings')
);
const UpdateWorkflowSettings = delayedLazy(
  () =>
    import(
      'views/WorkflowsAndActions/Modify/WorkflowSettings/UpdateWorkflowSettings/UpdateWorkflowSettings'
    )
);
const SetActions = delayedLazy(
  () => import('views/WorkflowsAndActions/Modify/SetActions/SetActions')
);
const Summary = delayedLazy(() => import('views/WorkflowsAndActions/Modify/Summary/Summary'));
const WorkflowAndActionsDetail = delayedLazy(
  () => import('views/WorkflowsAndActions/WorkflowSummaryPage')
);

export default function renderWorkflowsAndActionsRoutes(rtlsEnabledBranches: Branch[]) {
  return (
    <Route
      element={
        <RouteGuard authKey="workflowsAndActions" hidden={isArrayNullOrEmpty(rtlsEnabledBranches)}>
          <SuspenseOutlet />
        </RouteGuard>
      }
      path={WORKFLOW}
    >
      <Route element={<SuspenseOutlet />} path="workflows">
        <Route element={<WorkflowList />} path={WORKFLOW_LIST}>
          <Route element={<WorkflowAndActionsDetail />} path=":template/:id" />
        </Route>
        <Route element={<Outlet />} path="add">
          <Route element={<CreateWorkflowSettings />} path="workflowSettings/:template" />
          <Route
            element={<UpdateWorkflowSettings mode="add" />}
            path=":template/:workflowId/workflowSettings"
          />
          <Route element={<SetActions mode="add" />} path=":template/:workflowId/setActions" />
          <Route element={<Summary mode="add" />} path=":template/:workflowId/summary" />
        </Route>
        <Route element={<Outlet />} path="edit">
          <Route
            element={<UpdateWorkflowSettings mode="edit" />}
            path=":template/:workflowId/workflowSettings"
          />
          <Route element={<SetActions mode="edit" />} path=":template/:workflowId/setActions" />
          <Route element={<Summary mode="edit" />} path=":template/:workflowId/summary" />
        </Route>
        <Route element={<Navigate to="list" />} index />
      </Route>
      <Route element={<SuspenseOutlet />} path="actions">
        <Route element={<ActionList />} path={ACTION_LIST} />
        <Route element={<Navigate to="list" />} index />
      </Route>
      <Route element={<Navigate replace to="workflows" />} index />
    </Route>
  );
}
