import { NOTIFICATION_DETAIL } from 'routes/constant-route';
import { Route } from 'react-router-dom';
import { delayedLazy } from 'routes/helpers';

const NotificationsEntryPoint = delayedLazy(
  () => import('views/Notifications/Dialogs/RouteEntryPoint')
);

export default function renderNotificationRoutes() {
  return <Route element={<NotificationsEntryPoint />} path={NOTIFICATION_DETAIL} />;
}
