import { Navigate, Route } from 'react-router-dom';
import { RouteGuard } from 'components/RouteGuards/AuthGuard';
import { SuspenseOutlet, delayedLazy } from '../helpers';

const SystemNotifications = delayedLazy(
  () => import('views/NotificationManagement/SystemNotifications/SystemNotifications')
);

export default function renderNotificationManagementRoutes() {
  return (
    <Route
      element={
        <RouteGuard authKey="notificationConfiguration">
          <SuspenseOutlet />
        </RouteGuard>
      }
      path="notification-management"
    >
      <Route element={<SystemNotifications />} path="system-notifications" />
      <Route element={<Navigate replace to="system-notifications" />} index />
    </Route>
  );
}
