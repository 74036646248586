import {
  OpenDialogPayload,
  closeNotificationDialog,
  openNotificationDialog,
  selectNotificationDialogState
} from 'store/slices/notifications/dialog/notificationDialog';
import { useCallback } from 'react';
import { useTypedDispatch, useTypedSelector } from 'hooks';

export const useNotificationDialogState = () => {
  const notificationDialogState = useTypedSelector(selectNotificationDialogState);
  const dispatch = useTypedDispatch();

  const close = useCallback(() => {
    dispatch(closeNotificationDialog());
  }, [dispatch]);

  const openDialog = useCallback(
    (payload: OpenDialogPayload) => {
      dispatch(openNotificationDialog(payload));
    },
    [dispatch]
  );

  return { ...notificationDialogState, close, openDialog };
};
