import { Backdrop } from '@borda/cat-mui';
import { Player } from '@lottiefiles/react-lottie-player';

interface Props {
  open: boolean;
}

function LighthouseLoading(props: Props) {
  const { open } = props;

  return (
    <Backdrop open={open} sx={{ zIndex: 999999 }}>
      <Player
        autoplay
        background="transparent"
        className="h-[300px] w-[300px]"
        src="https://assets4.lottiefiles.com/private_files/lf30_L0FsjQ.json"
      />
    </Backdrop>
  );
}

export default LighthouseLoading;
