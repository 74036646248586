import { CatChip } from '@borda/cat-ui';
import { Collapse } from '@borda/cat-mui';
import { SearchResultType } from 'store/slices/search/types';
import { TransitionGroup } from 'react-transition-group';
import AssetCard from './AssetCard';
import CatOverlayScrollbarsComponent from 'components/OverlayScrollbars/CatOverlayScrollbarsComponent';
import clsx from 'clsx';

type Props = {
  cursor: number;
  onAssetSelect: (assetId: string) => Promise<void>;
  optionRefs?: React.RefObject<any>[];
  searchResult: SearchResultType[];
  searchResultsContainerRef: React.RefObject<any>;
};

function SearchResult(props: Props) {
  const { cursor, onAssetSelect, optionRefs, searchResult, searchResultsContainerRef } = props;

  return (
    <CatOverlayScrollbarsComponent
      className="grid max-h-60 grid-flow-row"
      ref={searchResultsContainerRef}
    >
      <TransitionGroup className="grid gap-2">
        {searchResult.map((searchItem, index) => {
          const showEnter = cursor === index;
          const showDownArrow = cursor === index - 1;
          const showUpArrow = cursor === index + 1;

          let label = '';
          if (index === cursor) {
            label = '⏎ Enter';
          } else if (cursor < index + 1) {
            label = '↓';
          } else if (cursor > index - 1) {
            label = '↑';
          }

          const showKeyboardLabel = showEnter || showDownArrow || showUpArrow;
          return (
            <Collapse key={searchItem.id}>
              <div className="flex" ref={optionRefs[index]}>
                <AssetCard
                  className={clsx(
                    'transition-opacity hover:opacity-100',
                    index === cursor ? 'opacity-100' : 'opacity-80'
                  )}
                  key={searchItem.id}
                  onAssetSelect={onAssetSelect}
                  searchItem={searchItem}
                />
                <div className="flex w-20 items-center justify-center">
                  <CatChip
                    classes={{
                      label: clsx('transition-[padding] duration-300', index !== cursor && 'px-1')
                    }}
                    className={clsx(
                      'transition-all duration-300',
                      showKeyboardLabel ? 'opacity-100' : 'opacity-0'
                    )}
                    label={label}
                    size="small"
                    variant={index !== cursor ? 'outlined' : undefined}
                  />
                </div>
              </div>
            </Collapse>
          );
        })}
      </TransitionGroup>
    </CatOverlayScrollbarsComponent>
  );
}

export default SearchResult;
