import { ASSET_DETAIL } from 'routes/constant-route';
import { CatPaper } from '@borda/cat-ui';
import { Divider } from '@borda/cat-mui';
import { Route } from '../RoutingButtons';
import { getDevelopmentFeaturesEnabled } from 'utils/settings';
import { selectIsUserAuthorized } from 'store/slices/session';
import { useCallback, useEffect, useRef } from 'react';
import { useCatNavigate } from '@borda/cat-core';
import { useTypedSelector } from 'hooks';
import AlertPanelButton from 'views/Alert/AlertPanel/Common/AlertPanelButton';
import AssetImportDialog from 'views/Asset/Assets/Import/AssetImportDialog';
import AssetSearchModal from './Search/AssetSearchModal';
import DynamicNotificationContainer from 'layouts/Dashboard/components/DynamicNotificationContainer';
import NotificationButton from 'views/Notifications/NotificationButton';
import ProfileButton from 'layouts/Dashboard/components/ProfileButton';
import RoutingButtons from 'components/RoutingButtons';
import SearchButton from './Search/SearchButton';

type Props = {
  assetSearchDialogOpen?: boolean;
  routes?: Route[];
  toggleAssetSearchDialog?: (status?: boolean) => void;
};

function TopBar(props: Props) {
  const navigate = useCatNavigate();
  const { assetSearchDialogOpen, routes, toggleAssetSearchDialog } = props;

  const developmentFeaturesEnabled = getDevelopmentFeaturesEnabled();

  const handleKeydown = useCallback(
    (e: any) => {
      if (e.code === 'KeyF' && e.altKey) {
        toggleAssetSearchDialog(true);
        e.preventDefault();
      }
    },
    [toggleAssetSearchDialog]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown, false);

    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [handleKeydown]);

  const openSearchModal = () => {
    toggleAssetSearchDialog();
  };

  const onAssetSelect = async (assetId: string) => {
    navigate(ASSET_DETAIL.replace(':id', assetId));
    toggleAssetSearchDialog();
  };

  const authorizedForAssetImport = useTypedSelector((state) =>
    selectIsUserAuthorized(state, 'assetImport')
  );

  const ref = useRef<HTMLDivElement>(null);

  return (
    <>
      <CatPaper className="flex justify-between px-4 py-3" ref={ref}>
        <div className="flex items-center">
          <SearchButton onClick={openSearchModal} />
          <Divider className="mx-4 my-3" flexItem orientation="vertical" />
          {routes && <RoutingButtons routes={routes} />}
        </div>
        <div className="flex items-center gap-4">
          <DynamicNotificationContainer />
          <ProfileButton />
          <Divider className="my-3" flexItem orientation="vertical" />
          <NotificationButton popoverAnchorRef={ref} />
          {developmentFeaturesEnabled && <AlertPanelButton popoverAnchorRef={ref} />}
        </div>
      </CatPaper>
      {authorizedForAssetImport && <AssetImportDialog />}
      <AssetSearchModal
        onAssetSelect={onAssetSelect}
        onClose={() => toggleAssetSearchDialog()}
        open={assetSearchDialogOpen}
      />
    </>
  );
}

export default TopBar;
