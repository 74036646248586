const ACCESSPOINTLIST = 'access_point_list';
const ACTIVETASKLISTTAB = 'active_task_list_tab';
const APPSETTINGS = 'app_settings';
const ASSETLIST = 'asset_list';
const BREKADOWNLIST = 'breakdown_list';
const CAMERA_LIST = 'camera_list';
const CATEGORYONBOARDINGMODALREAD = 'category_onboarding_modal_read';
const CONTRACTLIST = 'contract_list';
const COMMONFILTER = 'common';
const CYLERALIST = 'cylera_list';
const FIREBASETOKEN = 'firebase_token';
const MYASSETSLIST = 'my_asset_list';
const MYREQUESTLIST = 'my_request_list';
const PATIENTLIST = 'patient_list';
const PLANLIST = 'plan_list';
const PLANLISTLEGACY = 'plan_list_legacy';
const PROTECTIONCALENDAR = 'protection_calendar';
const PROTECTIONLIST = 'protection_list';
const PROTECTIONGROUPLIST = 'protection_group_list';
const TRACKABLEDEVICELIST = 'trackable_device_list';
const USERLIST = 'user_list';
const WFCLIST = 'wfc_list';
const MYEXPORTSLIST = 'my_exports_list';
const WORKFLOWEDITDIALOGREAD = 'workflow_edit_dialog_read';
const ALERTLIST = 'alert_list';
const ALERT_REDIRECT_CONFIRM = 'alert_redirect_confirm';

export const getColumnsKey = (key: string) => `quattro:columns:${key}`;
export const getFilterKey = (key: string) => `quattro:filter:${key}`;
export const getSettingsKey = (key: string) => `quattro:settings:${key}`;
export const getTutorialKey = (key: string) => `quattro:tutorial:${key}`;

export {
  ALERT_REDIRECT_CONFIRM,
  ACCESSPOINTLIST,
  ACTIVETASKLISTTAB,
  ALERTLIST,
  APPSETTINGS,
  ASSETLIST,
  BREKADOWNLIST,
  CAMERA_LIST,
  CATEGORYONBOARDINGMODALREAD,
  COMMONFILTER,
  CONTRACTLIST,
  CYLERALIST,
  FIREBASETOKEN,
  MYASSETSLIST,
  MYREQUESTLIST,
  PATIENTLIST,
  PLANLIST,
  PLANLISTLEGACY,
  PROTECTIONCALENDAR,
  PROTECTIONGROUPLIST,
  PROTECTIONLIST,
  TRACKABLEDEVICELIST,
  USERLIST,
  WFCLIST,
  MYEXPORTSLIST,
  WORKFLOWEDITDIALOGREAD
};
