import { DialogRequiredNotificationType } from 'views/Notifications/Dialogs/types';
import { NOTIFICATION_DETAIL } from 'routes/constant-route';
import { enqueueSnackbar } from 'notistack';
import { invalidateAlertQuery } from 'views/Alert/AlertPanel/queries';
import { matchPath } from 'react-router-dom';
import { showSnackbar } from './snackbar';
import { useCatNavigate } from '@borda/cat-core';
import { useMarkNotificationAsReadByIdMutation } from 'views/Notifications/queries';
import { useNotificationDialogState } from 'views/Notifications/Dialogs/helpers';

export type NotificationPaylod = {
  body: string;
  data: Record<string, string>;
  title: string;
  webLink: string;
};

export const useNotificationHandler = () => {
  const navigate = useCatNavigate();
  const {
    notificationType,
    open: notificationDialogOpen,
    openDialog
  } = useNotificationDialogState();

  const { mutateAsync: markNotificationAsRead } = useMarkNotificationAsReadByIdMutation();
  const isAlertDialogOpen = notificationDialogOpen && notificationType === 'AlertDialog';

  const handleAlertNotification = (payload: NotificationPaylod) => {
    const { body, data, title } = payload ?? {};
    const { alertDetail, alertId, popUpAlert } = data ?? {};

    invalidateAlertQuery();

    enqueueSnackbar({
      alertDetail: JSON.parse(alertDetail) as any,
      alertId,
      message: body,
      openNotificationDialog: openDialog,
      persist: true,
      title,
      variant: 'alert'
    });

    if (popUpAlert === true.toString() && !isAlertDialogOpen) {
      const alertButton = document.getElementById('alert-panel-btn');
      alertButton?.click();

      openDialog({ id: alertId, notificationType: 'AlertDialog' });
    }
  };

  const handleStandardNotification = (payload: NotificationPaylod) => {
    const { body, data, title, webLink } = payload ?? {};
    const { notificationId } = data ?? {};

    const onClick = () => {
      const { pathname: urlPathname, search } = new URL(webLink);
      const targetPath = urlPathname + search;

      if (notificationId) {
        markNotificationAsRead(notificationId);
      }

      const notificationDetailMatch = matchPath(NOTIFICATION_DETAIL, urlPathname);
      if (!notificationDetailMatch) {
        navigate(targetPath);
        return;
      }

      const {
        params: { id, notificationType }
      } = notificationDetailMatch;

      openDialog({
        id,
        notificationType: notificationType as DialogRequiredNotificationType
      });
    };

    showSnackbar({
      message: body,
      onClick: webLink ? onClick : undefined,
      title,
      type: 'success'
    });
  };

  const handleNotification = async (payload: NotificationPaylod) => {
    const { data } = payload ?? {};

    if (data?.isAlert === true.toString()) {
      handleAlertNotification(payload);
    } else {
      handleStandardNotification(payload);
    }
  };

  return { handleNotification };
};
