import { CatCheckbox, CatRadio, CatTypography } from '@borda/cat-ui';
import { ReactNode } from 'react';
import clsx from 'clsx';

type Props = {
  className?: string;
  description: React.ReactNode;
  icon?: ReactNode;
  id?: string;
  name?: string;
  onClick: () => void;
  selected: boolean;
  tabIndex?: number;
  variant?: 'checkbox' | 'radio';
};

function WorkflowCheckItem(props: Props) {
  const {
    className,
    description,
    icon,
    id,
    name,
    onClick,
    selected,
    tabIndex = 0,
    variant = 'checkbox'
  } = props;

  return (
    <div
      aria-checked={selected}
      className={clsx(
        selected ? 'bg-green/20' : 'bg-lightGrey',
        'hover:bg-darkGrey/10 flex w-full cursor-pointer items-center justify-between gap-2 rounded-lg p-3 duration-200',
        className
      )}
      onClick={onClick}
      role={variant}
      tabIndex={tabIndex}
    >
      <div className="flex items-center gap-3">
        {variant === 'checkbox' ? (
          <CatCheckbox checked={selected} id={id} name={name} paddingSize="none" size="medium" />
        ) : (
          <CatRadio checked={selected} id={id} name={name} size="medium" zeroPadding />
        )}
        <CatTypography className="select-none truncate" variant="body1">
          {description}
        </CatTypography>
      </div>
      {icon && icon}
    </div>
  );
}

export default WorkflowCheckItem;
