import { DialogRequiredNotificationType } from 'views/Notifications/Dialogs/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'RootTypes';

interface NotificationDialogState {
  id?: string;
  notificationType: DialogRequiredNotificationType;
  open: boolean;
}

const defaultNotificationDialog: NotificationDialogState = {
  id: null,
  notificationType: null,
  open: false
};

export type OpenDialogPayload = Omit<NotificationDialogState, 'open'>;

const notificationDialogSlice = createSlice({
  initialState: defaultNotificationDialog,
  name: 'notificationDialog',
  reducers: {
    closeNotificationDialog: (draft) => {
      draft.notificationType = defaultNotificationDialog.notificationType;
      draft.id = defaultNotificationDialog.id;
      draft.open = false;
    },
    openNotificationDialog: (draft, action: PayloadAction<OpenDialogPayload>) => {
      const { id, notificationType } = action.payload;

      if (id) {
        draft.id = id;
      }

      draft.notificationType = notificationType;
      draft.open = true;
    }
  }
});

export const selectNotificationDialogState = (state: RootState) => state.notifications.dialog;

export const { closeNotificationDialog, openNotificationDialog } = notificationDialogSlice.actions;

export default notificationDialogSlice.reducer;
