import { Alert } from 'views/Alert/types';
import { forwardRef } from 'react';
import { getAlertCardBorderHoverColor } from './helpers';
import { useNotificationDialogState } from 'views/Notifications/Dialogs/helpers';
import AlertCardHistory from './AlertCardHistory/AlertCardHistory';
import AlertLastResponse from './AlertLastResponse';
import AlertObjectType from './AlertObjectType/AlertObjectType';
import Header from './Header';
import clsx from 'clsx';

type Props = {
  index: number;
} & Alert;

function AlertCard(props: Props, ref: React.Ref<any>) {
  const {
    alertStartedLog,
    createDate,
    header,
    id,
    index,
    lastActivityLog,
    lastResponse,
    objectDetails,
    priority,
    status
  } = props;
  const { openDialog } = useNotificationDialogState();

  const borderHoverColor = getAlertCardBorderHoverColor(status);

  const handleAlertCardClick = () => {
    openDialog({ id, notificationType: 'AlertDialog' });
  };

  const isAlertClosed = status === 'closed';

  return (
    <div
      className={clsx(
        'bg-lightGrey flex flex-col gap-2 rounded-2xl border-2 border-transparent px-4 py-2 duration-200',
        borderHoverColor
      )}
      onClick={handleAlertCardClick}
      ref={ref}
      role="button"
      tabIndex={index}
    >
      <Header
        alertTitle={header}
        createDate={createDate}
        lastResponse={lastResponse}
        priority={priority}
        status={status}
      />
      <div className="divider-horizontal" />
      <AlertObjectType objectDetails={objectDetails} />
      <div className="divider-horizontal" />
      <AlertCardHistory alertStartedLog={alertStartedLog} lastActivityLog={lastActivityLog} />
      <div className="divider-horizontal" />
      <AlertLastResponse
        isDisabled={isAlertClosed}
        lastResponse={lastResponse}
        onClick={handleAlertCardClick}
        status={status}
      />
    </div>
  );
}

export default forwardRef(AlertCard);
