import { combineReducers } from '@reduxjs/toolkit';
import notificationDialogReducer from './dialog/notificationDialog';
import notificationFilterReducer from './filter/notificationFilter';
import notificationListReducer from './list/slice';

const notificationReducer = combineReducers({
  dialog: notificationDialogReducer,
  filter: notificationFilterReducer,
  list: notificationListReducer
});

export default notificationReducer;
