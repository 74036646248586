import { CatTypography } from '@borda/cat-ui';
import { LocationIcon } from '@borda/cat-icons';
import clsx from 'clsx';

type Props = {
  hideLastSeenTime?: boolean;
  lastSeenTime: string;
  locationFullPath: string;
  locationVariant: 'activeLocation' | 'alertLog';
};

function AlertCardLocation(props: Props) {
  const { hideLastSeenTime = false, lastSeenTime, locationFullPath, locationVariant } = props;

  const isAlertLog = locationVariant === 'alertLog';

  return (
    <>
      <div className="flex items-center">
        <CatTypography
          className={clsx(
            'truncate',
            isAlertLog ? 'text-darkGrey/80 max-w-72' : 'max-w-52 font-semibold'
          )}
          title={locationFullPath}
          variant="body2"
        >
          {locationFullPath}
        </CatTypography>
        {isAlertLog && (
          <LocationIcon className="text-darkGrey/80" fontSize="small" hoverable={false} />
        )}
      </div>
      {!hideLastSeenTime && (
        <>
          <CatTypography className="opactity-20 mx-1" variant="body2">
            |
          </CatTypography>
          <CatTypography className={isAlertLog && 'opacity-80'} variant="body2">
            {lastSeenTime}
          </CatTypography>
        </>
      )}
    </>
  );
}

export default AlertCardLocation;
