import { DialogRequiredNotificationType } from './types';
import { LazyExoticComponent, Suspense, lazy } from 'react';
import { useNotificationDialogState } from './helpers';
import LighthouseLoading from 'components/LighthouseLoading';

const delayedLazy: typeof lazy = (callback) =>
  lazy(() =>
    // eslint-disable-next-line no-promise-executor-return
    Promise.all([callback(), new Promise((res) => setTimeout(res, 500))]).then(
      ([moduleExports]) => moduleExports
    )
  );

const dynamicComponentMap: Record<
  DialogRequiredNotificationType,
  LazyExoticComponent<() => JSX.Element>
> = {
  AlertDialog: delayedLazy(() => import('views/Alert/AlertDialog/AlertDialogPage')),
  AssetCustodyApproved: delayedLazy(
    () => import('views/Asset/MyAssets/CustodyApproved/CustodyAssignmentApprovedDetail')
  ),
  AssetCustodyDenied: delayedLazy(
    () => import('views/Asset/MyAssets/CustodyDenied/CustodyAssignmentDeniedDetail')
  ),
  AssetCustodyRemoved: delayedLazy(
    () => import('views/Asset/MyAssets/MultiCustodyRemoved/CustodyRemovedNotificationDetail')
  ),
  AssetMarkedAsPassive: delayedLazy(
    () => import('views/Asset/MyAssets/PassiveMyAsset/PassiveMyAssetsNotificationDetail')
  ),
  AssetTransferred: delayedLazy(
    () => import('views/Asset/Assets/TransferNotification/NotificationDetail')
  ),
  AssetsTransferredCustodyRemoved: delayedLazy(
    () => import('views/Asset/MyAssets/TransferMyAsset/TransferMyAssetsNotificationDetail')
  ),
  BulkAssetInformationUpdated: delayedLazy(
    () => import('views/Asset/Assets/BulkAssetUpdated/BulkAssetUpdatedNotificationDetail')
  ),
  PlanAssociatedWithPlanNotRequired: delayedLazy(
    () =>
      import(
        'views/AssetConfiguration/Categories/PlanAssociationNotRequired/PlanAssociationNotRequiredDetail'
      )
  ),
  VideoCaptured: delayedLazy(
    () =>
      import(
        'views/ProfileSettings/AccountInformation/Notifications/VideoCaptured/VideoCapturedDetail'
      )
  )
};

function EntryPoint() {
  const { notificationType } = useNotificationDialogState();

  const DynamicComponent = dynamicComponentMap[notificationType];

  return (
    <Suspense fallback={<LighthouseLoading open />}>
      <DynamicComponent />
    </Suspense>
  );
}

export default EntryPoint;
