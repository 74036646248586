import { ASSETLIST, ASSET_DETAIL, CUSTODY_REQUEST_DETAILL } from 'routes/constant-route';
import { Navigate, Outlet, Route } from 'react-router-dom';
import { RouteGuard } from 'components/RouteGuards/AuthGuard';
import { SuspenseOutlet, delayedLazy } from '../helpers';
import { User, isUserAuthorized } from 'store/slices/session';

const AssetDetail = delayedLazy(() => import('views/Asset/Assets/AssetDetail/AssetDetail'));
const AssetBulkEdit = delayedLazy(() => import('views/Asset/Assets/BulkEdit/BulkEdit'));
const AssetAdd = delayedLazy(() => import('views/Asset/Assets/AssetDetail/AssetAdd'));
const AssetList = delayedLazy(() => import('views/Asset/Assets/AssetList/List/Assets'));
const MyAssetsList = delayedLazy(() => import('views/Asset/MyAssets/MyAssets'));

const CustodyAssignmentRequestDetail = delayedLazy(
  () => import('views/Asset/MyAssets/CustodyAssignmentRequest/CustodyAssignmentRequestDetail')
);

export default function renderAssetRoutes(sessionUser: User) {
  const isAuthorizedForAssetList = isUserAuthorized(sessionUser, 'assetList');

  return (
    <Route
      element={
        <RouteGuard products={['asset']}>
          <SuspenseOutlet />
        </RouteGuard>
      }
      path="asset"
    >
      <Route element={<Outlet />} path="assets">
        <Route
          element={
            <RouteGuard authKey="assetList">
              <AssetList />
            </RouteGuard>
          }
          path="list"
        />
        <Route
          element={
            <RouteGuard authKey="assetList">
              <AssetAdd
                getCancelNavigationAddress={() => ASSETLIST}
                getSaveNavigationAddress={(id: string) => `${ASSET_DETAIL.replace(':id', id)}`}
              />
            </RouteGuard>
          }
          path="add"
        />
        <Route element={<AssetDetail />} path=":id" />
        <Route element={<AssetBulkEdit />} path="bulk-edit" />
        <Route element={<Navigate replace to="list" />} index />
      </Route>
      <Route element={<Outlet />} path="my-assets">
        <Route element={<MyAssetsList />} path="list">
          <Route element={<CustodyAssignmentRequestDetail />} path={CUSTODY_REQUEST_DETAILL} />
        </Route>
        <Route element={<AssetDetail />} path=":id" />
        <Route element={<Navigate replace to="list" />} index />
      </Route>
      {isAuthorizedForAssetList ? (
        <Route element={<Navigate replace to="assets" />} index />
      ) : (
        <Route element={<Navigate replace to="my-assets" />} index />
      )}
    </Route>
  );
}
