import { AlertIcon } from '@borda/cat-icons';
import { AlertLogType, ObjectDetails, Priority, ResponseStatuses } from '../types';
import { CatTypography } from '@borda/cat-ui';
import { CloseIconButton } from 'packages/cat-wrappers';
import { CustomContentProps, closeSnackbar } from 'notistack';
import { OpenDialogPayload } from 'store/slices/notifications/dialog/notificationDialog';
import { Ref, forwardRef, useCallback } from 'react';
import { useDialogState } from '@borda/cat-core';
import { useNotificationDialogState } from 'views/Notifications/Dialogs/helpers';
import AlertObjectType from '../AlertPanel/AlertCard/AlertObjectType/AlertObjectType';
import PriorityChip from '../Common/PriorityChip';
import RedirectDialog from './RedirectDialog';
import clsx from 'clsx';

type AlertProps = {
  alertDetail: {
    alertStatus: ResponseStatuses;
    locationFullPath: string[];
    logType: AlertLogType;
    objectDetails: ObjectDetails;
    priority: Priority;
  };
  alertId: string;
  openNotificationDialog: (payload: OpenDialogPayload) => void;
  title: string;
};

interface Props extends AlertProps, CustomContentProps {
  message: string;
}

export const AlertSnackbar = forwardRef((props: Props, ref: Ref<any>) => {
  const {
    alertDetail: { logType, objectDetails, priority },
    alertId,
    className,
    id,
    message,
    openNotificationDialog,
    style,
    title
  } = props;

  const snackbarContentProps = { className, style };

  const { isOpen, togglePopup } = useDialogState();
  const { id: currentDialogAlertId, notificationType, open } = useNotificationDialogState();

  const handleClose = (e: any) => {
    closeSnackbar(id);
    e.stopPropagation();
  };

  const handleClick = () => {
    const isAlertDialogOpen = open && notificationType === 'AlertDialog';

    if (!isAlertDialogOpen) {
      openNotificationDialog({ id: alertId, notificationType: 'AlertDialog' });
      return;
    }

    if (currentDialogAlertId === alertId) {
      return;
    }

    togglePopup(true);
  };

  const handleRedirectConfirm = useCallback(() => {
    togglePopup(false);

    openNotificationDialog({ id: alertId, notificationType: 'AlertDialog' });

    closeSnackbar(id);
  }, [alertId, id, openNotificationDialog, togglePopup]);

  const isStarterAlert =
    logType === 'alertStarted' || logType === 'alertRepeated' || logType === 'retriggered';

  return (
    <>
      <RedirectDialog
        onClose={() => togglePopup(false)}
        onConfirm={handleRedirectConfirm}
        open={isOpen}
      />
      <div ref={ref} role="alert" {...snackbarContentProps}>
        <div
          className={clsx(
            'bg-lightGrey flex w-[480px] cursor-pointer flex-col gap-2 rounded-2xl border px-4 py-2',
            isStarterAlert ? 'border-red' : 'border-darkGrey'
          )}
          onClick={handleClick}
        >
          <div className="flex items-center gap-2">
            <AlertIcon color={isStarterAlert ? 'red' : 'darkGrey'} contained hoverable={false} />
            <CatTypography className="grow font-bold" variant="body1">
              {title}
            </CatTypography>
            <PriorityChip chipSize="small" priority={priority} variant="outlined" />
            <CloseIconButton onClick={handleClose} />
          </div>
          <CatTypography variant="body2">{message}</CatTypography>
          <div className="divider-horizontal" />
          <AlertObjectType hideLastSeenTime objectDetails={objectDetails} />
        </div>
      </div>
    </>
  );
});

declare module 'notistack' {
  interface VariantOverrides {
    alert: AlertProps;
  }
}
