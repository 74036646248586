import { ALERT_REDIRECT_CONFIRM } from 'helpers/localStorage/storageConstant';
import { CancelButton, ConfirmButton } from 'packages/cat-wrappers';
import {
  CatDialog,
  CatDialogAction,
  CatDialogContent,
  CatDialogTitle,
  CatTypography
} from '@borda/cat-ui';
import { CatTrans, useCatTranslation } from 'utils/localization';
import { InfoCautionIcon } from '@borda/cat-icons';
import { getSettingsKey } from 'helpers/localStorage';
import { loadFromLocalStorage, saveToLocalStorage } from '@borda/cat-core';
import { useEffect, useMemo, useState } from 'react';
import { withDialogWrapper } from 'packages/cat-wrappers/hooks';
import KeyboardButton from 'packages/cat-wrappers/KeyboardButton';
import WorkflowCheckItem from 'views/WorkflowsAndActions/Modify/common/WorkflowCheckItem';

interface Props {
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
}

function RedirectDialog(props: Props) {
  const { onClose, onConfirm, open } = props;
  const { t } = useCatTranslation('translation', { keyPrefix: 'alert.snackbar.redirect_dialog' });

  const [checked, setChecked] = useState(false);

  const alreadyConfirmed: boolean = useMemo(
    () => loadFromLocalStorage(getSettingsKey(ALERT_REDIRECT_CONFIRM)),
    []
  );

  useEffect(() => {
    if (alreadyConfirmed) {
      onConfirm();
    }
  }, [alreadyConfirmed, onConfirm]);

  if (alreadyConfirmed) {
    return null;
  }

  const handleConfirm = () => {
    if (checked) {
      saveToLocalStorage(getSettingsKey(ALERT_REDIRECT_CONFIRM), checked);
    }

    onConfirm();
  };

  return (
    <CatDialog open={open}>
      <CatDialogTitle iconComponent={InfoCautionIcon} title={t('title')} />
      <CatDialogContent className="flex flex-col gap-4">
        <CatTypography variant="body1">
          <CatTrans i18nKey="desc" t={t} />
        </CatTypography>
        <WorkflowCheckItem
          description={<CatTrans i18nKey="dont_show_again" t={t} />}
          onClick={() => setChecked((prev) => !prev)}
          selected={checked}
        />
      </CatDialogContent>
      <CatDialogAction>
        <KeyboardButton component={CancelButton} keyboardKey="Escape" onClick={onClose} />
        <KeyboardButton component={ConfirmButton} keyboardKey="Enter" onClick={handleConfirm} />
      </CatDialogAction>
    </CatDialog>
  );
}

export default withDialogWrapper(RedirectDialog);
