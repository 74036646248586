import { useNotificationDialogState } from 'views/Notifications/Dialogs/helpers';
import EntryPoint from 'views/Notifications/Dialogs/EntryPoint';

function DynamicNotificationContainer() {
  const { id, open } = useNotificationDialogState();

  if (!open) {
    return null;
  }

  return <EntryPoint key={id} />;
}

export default DynamicNotificationContainer;
