import { AnimatePresence } from 'framer-motion';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ProductKeys } from 'utils/settings';
import { Suspense } from 'react';
import {
  User,
  isUserAuthorized,
  selectAllMainCategories,
  selectClientSettings,
  selectSessionUser
} from 'store/slices/session';
import {
  renderAssetConfigurationRoutes,
  renderAssetDashboardRoutes,
  renderAssetRoutes,
  renderAttributeConfigurationRoutes,
  renderAuthRoutes,
  renderContractRoutes,
  renderDesignSystemRoutes,
  renderDevicesRoutes,
  renderErrorRoutes,
  renderIntegrationRoutes,
  renderLocationRoutes,
  renderMedicalUnitsRoutes,
  renderMetricsConfigurationRoutes,
  renderNotificationManagementRoutes,
  renderNotificationsRoutes,
  renderPatientRoutes,
  renderPlansRoutes,
  renderProfileSettingsRoutes,
  renderPublicRoutes,
  renderRealtimeTrackingRoutes,
  renderRequestRoutes,
  renderTaskConfigurationRoutes,
  renderTaskRoutes,
  renderUserRoutes,
  renderWorkflowsAndActionsRoutes
} from './Definitions';
import { selectEnabledProducts, selectRtlsEnabledBranches } from 'store/slices/branches';
import { useTypedSelector } from 'hooks';
import AuthenticationGuard from 'components/RouteGuards/AuthGuard';
import DashboardLayout from 'layouts/Dashboard/Dashboard';
import Redirecting from 'views/Auth/Redirecting';

function Routesv6() {
  return (
    <Suspense fallback={<Redirecting />}>
      <Routes>
        {renderAuthRoutes()} {/* auth/ */}
        {renderErrorRoutes()} {/* errors/ */}
        {renderPublicRoutes()} {/* public/ */}
        <Route element={<DashboardRoutes />} path="*" />
      </Routes>
    </Suspense>
  );
}

// We cannot use layout route since Routes elemnet needs a key in order to animate.
// This key causes rerender of layout on every route change (key=location.pathname).
// Therefore, Layout component needs to be outside of the Routes
function DashboardRoutes() {
  console.log('rerender');

  const location = useLocation();
  const mainCategories = useTypedSelector(selectAllMainCategories);
  const sessionUser = useTypedSelector(selectSessionUser);
  const rtlsEnabledBranches = useTypedSelector(selectRtlsEnabledBranches);
  const enabledProducts = useTypedSelector(selectEnabledProducts);
  const { cyleraEnable } = useTypedSelector(selectClientSettings);

  return (
    <DashboardWrapper>
      <Suspense fallback={<Redirecting />}>
        <AnimatePresence mode="wait">
          {/* The key prop can cause rerenders. It provides framer animation */}
          <Routes location={location}>
            {renderAssetRoutes(sessionUser)} {/* asset/ */}
            {renderAssetConfigurationRoutes()} {/* asset-configuration/ */}
            {renderAssetDashboardRoutes()} {/* asset-dashboard */}
            {renderAttributeConfigurationRoutes(sessionUser)} {/* {attribute-configuration} */}
            {renderContractRoutes()} {/* contract/ */}
            {renderDesignSystemRoutes()} {/* design-system/ */}
            {renderDevicesRoutes(rtlsEnabledBranches, sessionUser)} {/* devices/ */}
            {renderIntegrationRoutes(cyleraEnable, rtlsEnabledBranches)} {/* integration/ */}
            {renderLocationRoutes(rtlsEnabledBranches)} {/* location/ */}
            {renderMedicalUnitsRoutes()} {/* medical-units/ */}
            {renderMetricsConfigurationRoutes()} {/* metrics-configuration/ */}
            {renderNotificationsRoutes()} {/* notifications/ */}
            {renderNotificationManagementRoutes()} {/* notification-management/ */}
            {renderProfileSettingsRoutes()} {/* profile-settings/ */}
            {renderPatientRoutes()} {/* patients/ */}
            {renderPlansRoutes()} {/* Plans/ */}
            {renderRequestRoutes()} {/* request/ */}
            {renderRealtimeTrackingRoutes(rtlsEnabledBranches)} {/* realtime-tracking/ */}
            {renderTaskRoutes()} {/* task/ */}
            {renderTaskConfigurationRoutes(mainCategories)} {/* task-configuration/ */}
            {renderUserRoutes()} {/* user/ */}
            {renderWorkflowsAndActionsRoutes(rtlsEnabledBranches)} {/* workflows and actions */}
            {renderIndexRoute(enabledProducts, sessionUser)}
            <Route element={<Navigate to="/errors/error-404" />} path="*" />
          </Routes>
        </AnimatePresence>
      </Suspense>
    </DashboardWrapper>
  );
}

function renderIndexRoute(enabledProducts: ProductKeys[], sessionUser: User) {
  const getIndexRoutePath = () => {
    const assetEnabled = enabledProducts.includes('asset');
    if (assetEnabled) {
      return 'request';
    }

    const isUserAuthorizedForPatient = isUserAuthorized(sessionUser, 'patient');
    if (isUserAuthorizedForPatient) {
      return 'patient';
    }

    return 'profile-settings';
  };

  return <Route element={<Navigate replace to={getIndexRoutePath()} />} index />;
}

function DashboardWrapper(props: { children: React.ReactNode }) {
  const { children } = props;

  return (
    <AuthenticationGuard>
      <DashboardLayout>{children}</DashboardLayout>
    </AuthenticationGuard>
  );
}
export default Routesv6;
