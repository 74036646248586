import { Alert } from 'views/Alert/types';
import { LinearProgress } from '@borda/cat-mui';
import { isArrayNullOrEmpty } from '@borda/cat-core';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import AlertCard from '../AlertCard/AlertCard';
import CatOverlayScrollbarsComponent from 'components/OverlayScrollbars/CatOverlayScrollbarsComponent';
import EmptyContent from './EmptyContent';

type Props = {
  alerts: Alert[];
  alertsLoading: boolean;
  fetchNextPage: () => void;
  hasNextPage: boolean;
};

function PanelContent(props: Props) {
  const { alerts, alertsLoading, fetchNextPage, hasNextPage } = props;

  const isNoDataAvailable = isArrayNullOrEmpty(alerts) && !alertsLoading;

  const { inView, ref: infiniteScrollRef } = useInView();

  useEffect(() => {
    if (inView && hasNextPage) {
      // fetchNextPage cancels any running query
      // If query invalidation starts and fetchNextPage is called after that, invalidation fails
      // e.g. remounting the list with 3 items doesn't invalidate the query
      // Alternative: fetchNextPage?.({cancelRefetch:false}); without hasNextPage
      fetchNextPage?.();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  return (
    <div className="flex grow flex-col overflow-y-hidden px-4 py-2.5">
      {isNoDataAvailable ? (
        <EmptyContent />
      ) : (
        <>
          {alertsLoading && <LinearProgress className="w-full" />}
          <CatOverlayScrollbarsComponent innerContainerClassName="grid gap-2" shiftScrollbarBy={8}>
            {alerts.map((alert, index) => (
              <AlertCard
                {...alert}
                index={index}
                key={alert.id}
                ref={index === alerts.length - 1 ? infiniteScrollRef : null}
              />
            ))}
          </CatOverlayScrollbarsComponent>
        </>
      )}
    </div>
  );
}

export default PanelContent;
